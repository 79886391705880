import React from "react"
import { Helmet } from "react-helmet"

const BookAnEvent = (props: PageProps) => (
  <Helmet>
    <title>Book an Event</title>
    <meta name="robots" content="noindex" />
    <script src="https://api.tripleseat.com/v1/leads/ts_script.js?lead_form_id=18114&public_key=6916db452a5f9988297831634e96db5dd49e310e&inline_form=true" />
  </Helmet>
)

export default BookAnEvent
